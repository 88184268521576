import React, { useState, useEffect } from "react";
import {
  SaveBtn,
  addForecastSpecificationStartYearGreaterThanMRY,
  addForecastSpecificationStartYearLessThanFEY,
  endYearGreaterThanFEYear,
  endYearLessThanStartYear,
  paramNameValidationMsg,
  CAGRPercentageError,
  only2DecimalsAllowed,
  negativeNotallowed,
  maxlimitExceeded,
  setParameters,
  addGMPDataType,
  addGMPparameterType,
  addGMPParameterName,
  addGMPForceCost,
  addGMPVariableType,
  addGMPSourceYear,
  addGMPSource,
  addGMPDependency,
  addGMPAllocation,
  addGMPStartYearValue,
  addGMPCurrencyUnit,
  addGMPUnit,
  addGMPStartYear,
  addGMPEndyear,
  addGMPCAGR,
  addGMPDataTypeOptions,
  addGMPForceCostOptions,
  addGMPVariableTypeOptions,
  addGMPDependencyOptions,
  addGMPDependencyOptionsNew,
  decimalsNotAllowedError,
  setparametersTooltip,
  addGMPDatatypeTooltip,
  addGMPParamterTooltip,
  forceAsCostTooltip,
  vartypeTooltip,
  sourceYearTooltip,
  sourceTooltip,
  dependency,
  allocation,
  addGMPstartYearTooltip,
  currencyUnitTooltip,
  addGMPUnitTooltip,
  addGMPEndYearTooltip,
  CAGRTooltip,
  addGMPStartValueTooltip,
} from "../../../util/constants";
import { SaveIcon, RefreshIcon, AreaSeacrhIcon } from "../../../icons";
import { Select } from "../../Select/Select";
import { Button } from "../../Button/Button";
import { Input } from "../../Input/Input";
import { useDispatch, useSelector } from "react-redux";
import {
  saveGMPData,
  getGMPData,
  getParameterMatch,
} from "../../../store/services/gmp.service";
import {
  setGMPDetails,
  setVariableTypeValue,
} from "../../../store/slices/gmpSlice";
import { LoadingIndicatorEmbed } from "../../LoadingIndicator/LoadingIndicatorEmbed";
import { Tooltip } from "../../CommonUIComponents/Tooltip/Tooltip";
import { EmissionIcon } from "../../../icons";
import { ToastMessage } from "../../CommonUIComponents/ToastMessage/ToastMessage";
import { GMPHierarchy } from "../GMPHierarchy/GMPHierarchy";
import {
  setselectedUIAllFilters,
  setGMPHierarchy,
} from "../../../store/slices/gmpSlice";

export function GMPParameters() {
  const dispatch = useDispatch();
  const engagement = useSelector((state) => state.engagement.engagement);
  const project = useSelector((state) => state.project.project);
  const user = useSelector((state) => state.user.user);
  const gmp = useSelector((state) => state.gmp.gmp);
  const [gmpdataType, setGMPdataType] = useState("");
  const [gmpParameterName, setParameterName] = useState("");
  const [gmpForceCost, setForceCost] = useState("");
  const [gmpVariableType, setVariableType] = useState("");
  const [gmpSourceYear, setSourceYear] = useState("");
  const [gmpSource, setSource] = useState("");
  const [gmpDependency, setDependency] = useState("");
  const [gmpStartYearValue, setStartYearValue] = useState("");
  const [gmpCurrencyUnit, setCurrencyUnit] = useState("$");
  const [gmpUnit, setUnit] = useState("");
  const [gmpStartYear, setStartYear] = useState("");
  const [gmpEndYear, setEndyear] = useState("");
  const [gmpCAGR, setCAGR] = useState("");
  const decimalNumberRegExp = /^-?\d{1,11}(\.\d{1,2})?$/;
  const onlyNumbersRegExp = /^-?\d{1,11}$/;
  const [showstartYearError, setShowStartYearError] = useState(false);
  const [showendYearError, setShowEndYearError] = useState(false);
  const [simulateLoader, setSimulateloader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [valueErrorMessage, setValueErrorMessage] = useState("");
  const [valueError, setValueError] = useState(false);
  const [valueSYErrorMessage, setSYValueErrorMessage] = useState("");
  const [valueSYError, setSYValueError] = useState(false);
  const [CAGRErrorMessage, setCAGRErrorMessage] = useState("");
  const [CAGRError, setCAGRError] = useState(false);
  const [startYearErrorMessage, setStartYearErrorMessage] = useState("");
  const [endYearErrorMessage, setEndYearErrorMessage] = useState("");
  const [paramNameErrorMessage, setParamNameErrorMessage] = useState("");
  const [paramNameError, setParamNameError] = useState(false);
  const [isSaveBtn, setSaveBtn] = useState(true);
  const [isResetBtn, setResetBtn] = useState(true);
  const [toastMessage, setToastMessage] = useState(false);
  const [hideFieldForDisRate, setHideFieldForDisRate] = useState(false);
  const [hideFieldForIndependent, setHideFieldForIndependent] = useState(false);
  const [isHierarchyModalOpen, openHierarchyModal] = useState(false);

  // Close hierarcht popup
  const closeHierarchyModal = () => {
    openHierarchyModal(false);
  };
  // Function to show and hide toast message
  useEffect(() => {
    if (toastMessage) {
      setInterval(() => {
        setToastMessage(false);
      }, 5000);
    }
  }, [toastMessage]);

  //Onclick function on Simulate field
  const handleOnChange = (value, fieldName) => {
    setParamNameErrorMessage("");
    setParamNameError(false);
    if (fieldName === "gmpdataType") {
      setGMPdataType(value);
      setStartYearValue("");
      if (value === "Discount rate") {
        setForceCost("No");
        setVariableType("Dependent");
        setDependency("Emissions");
        setUnit("%");
        setHideFieldForDisRate(true);
        setHideFieldForIndependent(false);
      } else {
        setForceCost("");
        setVariableType("");
        setDependency("");
        setUnit("");
        setHideFieldForDisRate(false);
        setHideFieldForIndependent(true);
      }
    } else if (fieldName === "gmpParameterName") {
      setParameterName(value);
    } else if (fieldName === "gmpForceCost") {
      setForceCost(value);
    } else if (fieldName === "gmpVariableType") {
      if (value === "Independent") {
        // setUnit("");
        setHideFieldForIndependent(true);
      } else {
        setHideFieldForIndependent(false);
      }
      setVariableType(value);
      dispatch(setVariableTypeValue({ variableType: value }));
    } else if (fieldName === "gmpSourceYear") {
      setSourceYear(value);
    } else if (fieldName === "gmpSource") {
      setSource(value);
    } else if (fieldName === "gmpDependency") {
      setDependency(value);
      if (value === "Emissions") {
        setUnit("kgCO2e");
      } else {
        // setCurrencyUnit("");
        setUnit("");
      }
      if (value === "Not applicable" && gmpStartYearValue) {
        checkStartYearValueValidation(gmpStartYearValue);
      } else {
        checkStartYearValueValidationNew(gmpStartYearValue);
        setValueError(false);
      }
    }
    // else if (
    //   fieldName === "gmpStartYearValue" &&
    //   gmpDependency === "Not applicable"
    // ) {
    //   checkStartYearValueValidation(value);
    //   setStartYearValue(value);
    // }
    else if (
      fieldName === "gmpStartYearValue" &&
      gmpDependency !== "Not applicable"
    ) {
      checkStartYearValueValidationNew(value);
      setStartYearValue(value);
      setValueError(false);
    } else if (fieldName === "gmpCurrencyUnit") {
      setCurrencyUnit(value);
    } else if (fieldName === "gmpUnit") {
      setUnit(value);
    } else if (fieldName === "gmpStartYear") {
      checkStartYearValidation(value);
      setStartYear(value);
    } else if (fieldName === "gmpEndYear") {
      checkEndYearValidation(value);
      setEndyear(value);
    } else if (fieldName === "gmpCAGR") {
      checkCAGRvalidation(value);
      setCAGR(value);
    }
  };

  //Start year value validation for 0 to 100 in %
  const checkStartYearValueValidation = (value) => {
    setValueError(false);
    setValueErrorMessage("");
    if (value && value < 0) {
      setValueErrorMessage(negativeNotallowed);
      setValueError(true);
    } else if (value && !decimalNumberRegExp.test(value)) {
      setValueErrorMessage(only2DecimalsAllowed);
      setValueError(true);
    } else if (value && parseFloat(value) > 100) {
      setValueErrorMessage(maxlimitExceeded);
      setValueError(true);
    } else {
      setValueError(false);
      setValueErrorMessage("");
    }
  };

  //Start year value validation for upper limit 99999999999 and lower limit - 99999999999
  const checkStartYearValueValidationNew = (value) => {
    setSYValueErrorMessage("");
    setSYValueError(false);
    if ((value && value > 99999999999.99) || value < -99999999999.99) {
      setSYValueErrorMessage(maxlimitExceeded);
      setSYValueError(true);
    } else if (value && !decimalNumberRegExp.test(value) && value !== "-") {
      setSYValueErrorMessage(only2DecimalsAllowed);
      setSYValueError(true);
    } else {
      setSYValueErrorMessage("");
      setSYValueError(false);
    }
  };

  //CAGR value validation for -100 % to 9999.99 in %
  const checkCAGRvalidation = (value) => {
    if ((value && value < -100) || value > 9999.99) {
      setCAGRErrorMessage(CAGRPercentageError);
      setCAGRError(true);
    } else if (value && !decimalNumberRegExp.test(value)) {
      setCAGRError(true);
      setCAGRErrorMessage(only2DecimalsAllowed);
    } else {
      setCAGRError(false);
      setCAGRErrorMessage("");
    }
  };

  // Disable Save Button logic
  useEffect(() => {
    if (
      gmpdataType &&
      gmpParameterName &&
      gmpVariableType &&
      gmpStartYearValue &&
      gmpCurrencyUnit &&
      gmpStartYear &&
      gmpEndYear &&
      // gmpCAGR &&
      ((gmpVariableType === "Dependent" &&
        gmpDependency &&
        gmpForceCost &&
        gmpUnit &&
        gmp?.gmpHierarchyDataSet &&
        Object.keys(gmp?.gmpHierarchyDataSet).length > 0) ||
        gmpVariableType === "Independent") &&
      !valueError &&
      !valueSYError &&
      !CAGRError &&
      !showstartYearError &&
      !showendYearError &&
      !paramNameError
    ) {
      setSaveBtn(false);
    }
    // else if (
    //   gmpdataType &&
    //   gmpParameterName &&
    //   gmpForceCost &&
    //   gmpVariableType &&
    //   gmpDependency === "Not applicable" &&
    //   gmpStartYearValue &&
    //   gmpStartYear &&
    //   gmpEndYear &&
    //   // gmpCAGR &&
    //   ((gmpVariableType === "Dependent" &&
    //     gmp?.gmpHierarchyDataSet &&
    //     Object.keys(gmp?.gmpHierarchyDataSet).length > 0) ||
    //     gmpVariableType === "Independent") &&
    //   !valueError &&
    //   !valueSYError &&
    //   !CAGRError &&
    //   !showstartYearError &&
    //   !showendYearError &&
    //   !paramNameError
    // ) {
    //   setSaveBtn(false);
    // }
    else {
      setSaveBtn(true);
    }
  });

  // Disable Reset Button logic
  useEffect(() => {
    if (
      gmpdataType &&
      gmpParameterName &&
      gmpForceCost &&
      gmpVariableType &&
      gmpDependency &&
      gmpStartYearValue &&
      gmpCurrencyUnit &&
      gmpUnit &&
      gmpStartYear &&
      gmpEndYear
      // gmpCAGR
    ) {
      setResetBtn(false);
    }
    // else if (
    //   gmpdataType &&
    //   gmpParameterName &&
    //   gmpForceCost &&
    //   gmpVariableType &&
    //   (gmpDependency === "Not applicable" ||
    //     gmpDependency === "Activity data") &&
    //   gmpStartYearValue &&
    //   gmpStartYear &&
    //   gmpEndYear
    //   // gmpCAGR
    // ) {
    //   setResetBtn(false);
    // }
    else if (
      gmpdataType &&
      gmpParameterName &&
      ((gmpVariableType === "Dependent" && gmpForceCost && gmpDependency) ||
        gmpVariableType === "Independent") &&
      gmpStartYearValue &&
      gmpStartYear &&
      gmpEndYear
      // gmpCAGR
    ) {
      setResetBtn(false);
    } else {
      setResetBtn(true);
    }
  });

  useEffect(() => {
    dispatch(setVariableTypeValue({ variableType: gmpVariableType }));
  }, [gmpVariableType]);

  // Check Duplicate Param Name
  const checkDuplicateParamName = async () => {
    if (
      gmpdataType !== "" &&
      gmpVariableType !== ""
      // gmpDependency !== "" &&
      // gmpForceCost !== ""
    ) {
      const payload = {
        gmP_data_type: gmpdataType,
        variable_type: gmpVariableType,
        dependency: gmpDependency ? gmpDependency : "Emissions",
        parameter_name: gmpParameterName,
        force_as_cost: gmpForceCost ? gmpForceCost : "No",
        engagementCode: engagement.engagementCode,
      };
      const data = await getParameterMatch({ payload });
      return data;
    }
  };

  // Check Year validation
  const checkStartYearValidation = (value) => {
    const mostRecentYear = engagement.mostRecentYear;
    if (value && parseFloat(value) <= mostRecentYear) {
      setStartYearErrorMessage(addForecastSpecificationStartYearGreaterThanMRY);
      setShowStartYearError(true);
    } else if (value && parseFloat(value) > engagement?.foreCastEndYear) {
      setStartYearErrorMessage(addForecastSpecificationStartYearLessThanFEY);
      setShowStartYearError(true);
    } else {
      setStartYearErrorMessage("");
      setShowStartYearError(false);
    }
  };

  const checkEndYearValidation = (value) => {
    const forecastEndYear = engagement.foreCastEndYear;
    if (value && parseFloat(value) > forecastEndYear) {
      setEndYearErrorMessage(endYearGreaterThanFEYear);
      setShowEndYearError(true);
    } else if (value && gmpStartYear && parseFloat(value) < gmpStartYear) {
      setEndYearErrorMessage(endYearLessThanStartYear);
      setShowEndYearError(true);
    } else {
      setEndYearErrorMessage("");
      setShowEndYearError(false);
    }
  };

  // On applying Simulate
  const onReset = () => {
    setSaveLoader(false);
    setGMPdataType("");
    setParameterName("");
    setForceCost("");
    setVariableType("");
    setSourceYear("");
    setSource("");
    setDependency("");
    setStartYearValue("");
    setSYValueError(false);
    setSYValueErrorMessage("");
    // setCurrencyUnit("");
    setUnit("");
    setStartYear("");
    setEndyear("");
    setCAGR("");
    setValueError(false);
    setCAGRError(false);
    setShowStartYearError(false);
    setShowEndYearError(false);
    setParamNameError(false);
    setHideFieldForDisRate(false);
    setHideFieldForIndependent(false);
    dispatch(setGMPHierarchy({ gmpHierarchyData: {} }));
    dispatch(setselectedUIAllFilters({ selectedUIAllFilters: [] }));
  };

  const onSave = async () => {
    setSaveLoader(true);
    let paramValidation = await checkDuplicateParamName();
    if (paramValidation.success === true) {
      let independentHierachrchy = [
        {
          columnType: "Scope",
          columnValue: "All",
        },
        {
          columnType: "Category_level1",
          columnValue: "All",
        },
        {
          columnType: "Category_level2",
          columnValue: "All",
        },
        {
          columnType: "Category_level3",
          columnValue: "All",
        },
        {
          columnType: "Region_level1",
          columnValue: "All",
        },
        {
          columnType: "Region_level2",
          columnValue: "All",
        },
        {
          columnType: "Region_level3",
          columnValue: "All",
        },
        {
          columnType: "Entity_level1",
          columnValue: "All",
        },
        {
          columnType: "Entity_level2",
          columnValue: "All",
        },
        {
          columnType: "Entity_level3",
          columnValue: "All",
        },
        {
          columnType: "Entity_level4",
          columnValue: "All",
        },
        {
          columnType: "Custom_specification1",
          columnValue: "All",
        },
        {
          columnType: "Custom_specification2",
          columnValue: "All",
        },
        {
          columnType: "Custom_specification3",
          columnValue: "All",
        },
      ];
      const payload = {
        engagementCode: engagement.engagementCode,
        engagementID: engagement.id,
        projectID: "00000000-0000-0000-0000-000000000000",
        projectName: "Test",
        moduleName: "Save-GMPRule",
        parameter_name: gmpParameterName,
        gmP_data_type: gmpdataType,
        force_as_cost: gmpForceCost ? gmpForceCost : "No",
        variable_type: gmpVariableType,
        source_year: Number(gmpSourceYear),
        source: gmpSource,
        dependency: gmpDependency ? gmpDependency : "Emissions",
        start_Year_Value: Number(gmpStartYearValue),
        currency_unit: gmpCurrencyUnit,
        unit: gmpUnit,
        start_year: Number(gmpStartYear),
        end_year: Number(gmpEndYear),
        cagr: Number(gmpCAGR),
        modifiedby: user.username,
        createdBy: user.username,
        hierarchialData:
          gmpVariableType === "Independent"
            ? independentHierachrchy
            : gmp.gmpHierarchyDataSet,
      };
      await saveGMPData({ payload });
      setToastMessage(true);
      updateGMPTable();
    } else {
      setParamNameError(true);
      setParamNameErrorMessage(paramNameValidationMsg);
    }
    setSaveLoader(false);
  };

  // Get data to refresh GMP table
  const updateGMPTable = async () => {
    const request = {
      engagement: engagement.engagementCode,
      viewType: "Default",
    };
    const data = await getGMPData({ request });
    dispatch(setGMPDetails({ gmpData: data }));
  };
  return (
    <div className="add-gmp-params-section">
      <div className="add-gmp-params-title">
        <span>{setParameters}</span>
        <Tooltip
          trigger={<EmissionIcon />}
          variant="ghost"
          tooltipText={setparametersTooltip}
        ></Tooltip>
      </div>

      <div class="add-gmp-params-content">
        <div class="motif-row">
          <div class="motif-col-xs-2">
            <div className="add-gmp-params-content-row-dropdown">
              <span class="add-gmp-params-content-dropdowns-label">
                {addGMPParameterName}
                <span className="required-star">*</span>
                <Tooltip
                  trigger={<EmissionIcon />}
                  variant="ghost"
                  tooltipText={addGMPParamterTooltip}
                ></Tooltip>
              </span>
              <Input
                type="text"
                onChange={(event) => {
                  handleOnChange(event?.target?.value, "gmpParameterName");
                }}
                errorMessage={paramNameErrorMessage}
                isError={paramNameError}
                value={gmpParameterName}
                maxLength={100}
              ></Input>
            </div>
          </div>
          <div class="motif-col-xs-1">
            <div className="add-gmp-params-content-row-dropdown">
              <span class="add-gmp-params-content-dropdowns-label">
                {addGMPparameterType} <span className="required-star">*</span>{" "}
                <Tooltip
                  trigger={<EmissionIcon />}
                  variant="ghost"
                  tooltipText={addGMPDatatypeTooltip}
                ></Tooltip>
              </span>
              <Select
                options={addGMPDataTypeOptions}
                value={gmpdataType}
                onChange={(event) => {
                  handleOnChange(event, "gmpdataType");
                }}
              ></Select>
            </div>
          </div>
          <div class="motif-col-xs-1">
            <div className="add-gmp-params-content-row-dropdown">
              <span class="add-gmp-params-content-dropdowns-label">
                {addGMPVariableType} <span className="required-star">*</span>
                <Tooltip
                  trigger={<EmissionIcon />}
                  variant="ghost"
                  tooltipText={vartypeTooltip}
                ></Tooltip>
              </span>
              <Select
                options={addGMPVariableTypeOptions}
                value={gmpVariableType}
                onChange={(event) => {
                  handleOnChange(event, "gmpVariableType");
                }}
                disabled={gmpdataType === "Discount rate" ? true : false}
              ></Select>
            </div>
          </div>
        </div>

        <div class="motif-row">
          <div class="motif-col-xs-2">
            <div className="add-gmp-params-content-row-dropdown">
              <span class="add-gmp-params-content-dropdowns-label">
                {addGMPSource}
                <Tooltip
                  trigger={<EmissionIcon />}
                  variant="ghost"
                  tooltipText={sourceTooltip}
                ></Tooltip>
              </span>
              <Input
                type="text"
                onChange={(event) => {
                  handleOnChange(event?.target?.value, "gmpSource");
                }}
                value={gmpSource}
                maxLength={500}
              ></Input>
            </div>
          </div>
          <div class="motif-col-xs-1">
            <div className="add-gmp-params-content-row-dropdown">
              <span class="add-gmp-params-content-dropdowns-label">
                {addGMPSourceYear}
                <Tooltip
                  trigger={<EmissionIcon />}
                  variant="ghost"
                  tooltipText={sourceYearTooltip}
                ></Tooltip>
              </span>
              <Input
                type="number"
                onChange={(event) => {
                  handleOnChange(event?.target?.value, "gmpSourceYear");
                }}
                value={gmpSourceYear}
                maxLength={4}
              ></Input>
            </div>
          </div>
        </div>
        {hideFieldForIndependent === true ? (
          <></>
        ) : (
          <div class="motif-row">
            <div class="motif-col-xs-2">
              <Button
                variant="primary-alt"
                onClick={() => {
                  openHierarchyModal(true);
                }}
                className="add-gmp-params-data-selection-btn"
              >
                <AreaSeacrhIcon />
                Data selection <span className="required-star">*</span>
              </Button>
            </div>
          </div>
        )}
        <GMPHierarchy
          isFilterOpen={isHierarchyModalOpen}
          onClose={closeHierarchyModal}
        />

        <div class="motif-row">
          {hideFieldForIndependent === true || hideFieldForDisRate === true ? (
            <></>
          ) : (
            <div class="motif-col-xs-1">
              <div className="add-gmp-params-content-row-dropdown">
                {/* {renameDependency === true ? (
                <span class="add-gmp-params-content-dropdowns-label">
                  {addGMPAllocation} <span className="required-star">*</span>
                  <Tooltip
                    trigger={<EmissionIcon />}
                    variant="ghost"
                    tooltipText={allocation}
                  ></Tooltip>
                </span>
              ) : (
                <span class="add-gmp-params-content-dropdowns-label">
                  {addGMPDependency} <span className="required-star">*</span>
                  <Tooltip
                    trigger={<EmissionIcon />}
                    variant="ghost"
                    tooltipText={dependency}
                  ></Tooltip>
                </span>
              )} */}
                <span class="add-gmp-params-content-dropdowns-label">
                  {addGMPDependency} <span className="required-star">*</span>
                  <Tooltip
                    trigger={<EmissionIcon />}
                    variant="ghost"
                    tooltipText={dependency}
                  ></Tooltip>
                </span>
                <Select
                  options={
                    gmpdataType === "Discount rate"
                      ? addGMPDependencyOptions
                      : addGMPDependencyOptionsNew
                  }
                  value={gmpDependency}
                  onChange={(event) => {
                    handleOnChange(event, "gmpDependency");
                  }}
                  disabled={gmpdataType === "Discount rate" ? true : false}
                ></Select>
              </div>
            </div>
          )}
          {hideFieldForIndependent === true || hideFieldForDisRate === true ? (
            <></>
          ) : (
            <div class="motif-col-xs-1">
              <div className="add-gmp-params-content-row-dropdown">
                <span class="add-gmp-params-content-dropdowns-label">
                  {addGMPForceCost} <span className="required-star">*</span>
                  <Tooltip
                    trigger={<EmissionIcon />}
                    variant="ghost"
                    tooltipText={forceAsCostTooltip}
                  ></Tooltip>
                </span>
                <Select
                  options={addGMPForceCostOptions}
                  value={gmpForceCost}
                  onChange={(event) => {
                    handleOnChange(event, "gmpForceCost");
                  }}
                  disabled={gmpdataType === "Discount rate" ? true : false}
                ></Select>
              </div>
            </div>
          )}
        </div>
        <div class="motif-row">
          <div class="motif-col-xs-1">
            <div className="add-gmp-params-content-row-dropdown">
              <span class="add-gmp-params-content-dropdowns-label">
                {addGMPStartYear}
                <span className="required-star">*</span>
                <Tooltip
                  trigger={<EmissionIcon />}
                  variant="ghost"
                  tooltipText={addGMPstartYearTooltip}
                ></Tooltip>
              </span>
              <Input
                type="number"
                errorMessage={startYearErrorMessage}
                isError={showstartYearError}
                disableErrorPlaceholder={true}
                onChange={(event) => {
                  handleOnChange(event?.target?.value, "gmpStartYear");
                }}
                value={gmpStartYear}
                maxLength={4}
              />
            </div>
          </div>
          <div class="motif-col-xs-1">
            <div className="add-gmp-params-content-row-dropdown">
              <span class="add-gmp-params-content-dropdowns-label">
                {addGMPEndyear}
                <span className="required-star">*</span>
                <Tooltip
                  trigger={<EmissionIcon />}
                  variant="ghost"
                  tooltipText={addGMPEndYearTooltip}
                ></Tooltip>
              </span>
              <Input
                type="number"
                errorMessage={endYearErrorMessage}
                isError={showendYearError}
                disableErrorPlaceholder={true}
                onChange={(event) => {
                  handleOnChange(event?.target?.value, "gmpEndYear");
                }}
                value={gmpEndYear}
                maxLength={4}
              />
            </div>
          </div>
          <div class="motif-col-xs-1">
            {/* {gmpDependency === "Not applicable" ? (
              <>
                <div className="add-gmp-params-content-row-dropdown">
                  <span class="add-gmp-params-content-dropdowns-label">
                    {addGMPStartYearValue}
                    <span className="required-star">*</span>
                    <Tooltip
                      trigger={<EmissionIcon />}
                      variant="ghost"
                      tooltipText={addGMPstartYearTooltip}
                    ></Tooltip>
                  </span>
                  <Input
                    type="decimal"
                    errorMessage={valueErrorMessage}
                    isError={valueError}
                    onChange={(event) => {
                      handleOnChange(event?.target?.value, "gmpStartYearValue");
                    }}
                    value={gmpStartYearValue}
                  ></Input>
                </div>{" "}
              </>
            ) : ( */}
            <>
              <div className="add-gmp-params-content-row-dropdown">
                <span class="add-gmp-params-content-dropdowns-label">
                  {addGMPStartYearValue}
                  <span className="required-star">*</span>
                  <Tooltip
                    trigger={<EmissionIcon />}
                    variant="ghost"
                    tooltipText={addGMPStartValueTooltip}
                  ></Tooltip>
                </span>
                <Input
                  type="decimal"
                  errorMessage={valueSYErrorMessage}
                  isError={valueSYError}
                  onChange={(event) => {
                    handleOnChange(event?.target?.value, "gmpStartYearValue");
                  }}
                  value={gmpStartYearValue}
                ></Input>
              </div>
            </>
            {/* )} */}
          </div>
          <div class="motif-col-xs-1">
            <div className="add-gmp-params-content-row-dropdown">
              <span class="add-gmp-params-content-dropdowns-label">
                {addGMPCAGR}
                {/* <span className="required-star">*</span> */}
                <Tooltip
                  trigger={<EmissionIcon />}
                  variant="ghost"
                  tooltipText={CAGRTooltip}
                ></Tooltip>
              </span>
              <Input
                // errorMessage={startYearErrorMessage}
                // isError={showstartYearError}
                type="decimal"
                disableErrorPlaceholder={true}
                onChange={(event) => {
                  handleOnChange(event?.target?.value, "gmpCAGR");
                }}
                errorMessage={CAGRErrorMessage}
                isError={CAGRError}
                value={gmpCAGR}
              />
            </div>
          </div>
        </div>

        <div class="motif-row">
          {hideFieldForDisRate === true ? (
            <></>
          ) : (
            <div class="motif-col-xs-1">
              <div className="add-gmp-params-content-row-dropdown">
                <span class="add-gmp-params-content-dropdowns-label">
                  {addGMPCurrencyUnit}
                  <span className="required-star">*</span>
                  <Tooltip
                    trigger={<EmissionIcon />}
                    variant="ghost"
                    tooltipText={currencyUnitTooltip}
                  ></Tooltip>
                </span>
                <Input
                  type="text"
                  onChange={(event) => {
                    handleOnChange(event?.target?.value, "gmpCurrencyUnit");
                  }}
                  value={gmpCurrencyUnit}
                  // disabled={gmpDependency === "Not applicable" ? true : false}
                  disabled={true}
                  maxLength={30}
                ></Input>
              </div>
            </div>
          )}
          {hideFieldForIndependent === true || hideFieldForDisRate === true ? (
            <></>
          ) : (
            <div class="motif-col-xs-1">
              <div className="add-gmp-params-content-row-dropdown">
                <span class="add-gmp-params-content-dropdowns-label">
                  {addGMPUnit}
                  <span className="required-star">*</span>
                  <Tooltip
                    trigger={<EmissionIcon />}
                    variant="ghost"
                    tooltipText={addGMPUnitTooltip}
                  ></Tooltip>
                </span>
                <Input
                  type="text"
                  onChange={(event) => {
                    handleOnChange(event?.target?.value, "gmpUnit");
                  }}
                  value={gmpUnit}
                  disabled={
                    gmpDependency === "Not applicable" ||
                    gmpDependency === "Emissions" ||
                    gmpdataType === "Discount rate" ||
                    (gmpDependency === "Activity data" &&
                      gmpVariableType === "Independent")
                      ? true
                      : false
                  }
                ></Input>
              </div>
            </div>
          )}
        </div>
        <div class="motif-row">
          <div class="motif-col-xs-1">
            <div className="add-gmp-params-buttons">
              <div className="add-gmp-params-buttons-div">
                <LoadingIndicatorEmbed show={simulateLoader}>
                  <Button
                    variant="primary-alt"
                    onClick={onReset}
                    disabled={isResetBtn}
                  >
                    <RefreshIcon />
                    Reset
                  </Button>
                </LoadingIndicatorEmbed>
              </div>
              <LoadingIndicatorEmbed show={saveLoader}>
                <Button
                  variant="primary"
                  className="add-gmp-params-button-save"
                  onClick={() => onSave()}
                  disabled={isSaveBtn}
                >
                  <SaveIcon />
                  {SaveBtn}
                </Button>
              </LoadingIndicatorEmbed>
            </div>
          </div>
        </div>
      </div>
      <div className="add-gmp-params-footer">
        {toastMessage && (
          <ToastMessage
            onClose={() => setToastMessage(false)}
            message={"Financial parameter added successfully"}
            variant={"success"}
          ></ToastMessage>
        )}
      </div>
    </div>
  );
}
