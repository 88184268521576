import { DeleteLogo, DownloadIcon, LibraryIcon } from "../../icons";
import { GraphIcon } from "../../icons";
import { useNavigate } from "react-router-dom";
import { MotifIconButton } from "@ey-xd/motif-react";
import { routes } from "../../routes/routes.constatns";
import { memo } from "react";
import { useSelector } from "react-redux";

export default memo(
  ({
    node,
    onDeleteClick,
    onMoveProject,
    isAdmin,
    isDataNotinSync,
    onExportClick,
  }) => {
    const navigate = useNavigate();
    const selectedRegion = useSelector(
      (state) => state.engagement.selectedRegion
    );

    function onViewProjectClickFn(projectId, engagementId) {
      navigate(`${routes.createNewProject}`, {
        state: {
          engagementId: engagementId,
          projectId: projectId,
          regionId: selectedRegion.id,
        },
        gestureEnabled: false,
      });
    }
    const user = useSelector((state) => state.user?.user?.username);

    // Check if the project is locked
    const isProjectLocked =
      node.data.isLocked === 1 && node.data.lockedby !== user;

    return (
      <div className="analyze-table-action">
        <MotifIconButton
          onClick={() => {
            onViewProjectClickFn(node.data.id, node.data.engagementID);
          }}
          disabled={isDataNotinSync || isProjectLocked}
        >
          <GraphIcon></GraphIcon>
        </MotifIconButton>
        {isAdmin && node.data.projectStatusID === 5 && (
          <MotifIconButton
            onClick={() => {
              onMoveProject(node.data);
            }}
            title="Move project to library"
            disabled={isDataNotinSync || isProjectLocked}
          >
            <LibraryIcon />
          </MotifIconButton>
        )}
        <MotifIconButton
          onClick={() => {
            onExportClick(node.data);
          }}
          disabled={isProjectLocked}
        >
          <DownloadIcon />
        </MotifIconButton>
        <MotifIconButton
          onClick={() => {
            onDeleteClick(node.data);
          }}
          disabled={isProjectLocked}
        >
          <DeleteLogo></DeleteLogo>
        </MotifIconButton>
      </div>
    );
  }
);
